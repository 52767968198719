import { defineComponent, watch } from "vue";

export default defineComponent({
  props: {
    icon: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    return () => <div>
      <a href={ props.icon.url }>
        <img src={ props.icon.images[0] } alt="" srcset={ `${ props.icon.images[1] } 2x,${ props.icon.images[2] } 3x` } />
      </a>
    </div>
  }
});
